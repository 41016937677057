import { createSlice } from '@reduxjs/toolkit'

export const inventoryDecider = createSlice({
  name: 'inventory',
  initialState: {
    value: localStorage.getItem('inventory') == undefined ? 0 : JSON.parse(localStorage.getItem('inventory'))
  },
  reducers: {
    toStandard: (state) => {
      state.value = 0
      localStorage.setItem('inventory', 0);
    },
    toUpper: (state) => {
      state.value = 1
      localStorage.setItem('inventory', 1);
    },
    toLower: (state) => {
      state.value = 2
      localStorage.setItem('inventory', 2);
    },
  },
})

// Action creators are generated for each case reducer function
export const { toStandard, toUpper,toLower } = inventoryDecider.actions

export default inventoryDecider.reducer