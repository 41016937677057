import React, { useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";

import xartoki from "assets/img/Product-Services/Xartokibwtia.jpg";
import xartina_koutia from "assets/img/Product-Services/Xartina_koytia.jpg";
import etiketes from "assets/img/Product-Services/Etiketes.jpg";
import peritili from "assets/img/Product-Services/Film_peritiliksis.jpg";
import thermosiriknosi from "assets/img/Product-Services/Film_thermo.jpg";
import kollTainies from "assets/img/Product-Services/Kollitikes_tainies_PPL.jpg";
import gonies from "assets/img/Product-Services/Gonies_Paletopoiisis.jpg";
import megaSakoi from "assets/img/Product-Services/Mega_Sakoi.jpg";
import typomenesTainies from "assets/img/Product-Services/Typomenes_tainies.jpg";
import grafiki from "assets/img/Product-Services/Grafiki_ili.jpg";
import emporikaEntypa from "assets/img/Product-Services/Emporika_entypa.jpg";
import entypaAsfalias from "assets/img/Product-Services/Entypa_Asfalias.jpg";
import epistolikiEpikoinwnia from "assets/img/Product-Services/Epistoliki_epikoinwnia.jpg";

import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderCenterLinks from "components/Header/HeaderCenterLinks.js";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Paper from "@material-ui/core/Paper";

import { useSelector, useDispatch } from 'react-redux'
import { toStandard, toUpper,toLower } from '../../features/inventoryDecider'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 650,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxHeight: 440,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // centerPadding: "100px",
  // centerMode: true,
};

// const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const classes = useStyles();
  const inventory = useSelector((state) => state.inventory.value)
  const dispatch = useDispatch()
  // const [selected, setSelected] = React.useState([]);

  return (
    <div className={classes.root}>
      <Header
        // <Logo_tmp
        //   onClick={() => {
        //     window.location.href = "/";
        //   }}
        // />

        rightLinks={<HeaderLinks />}
        centerLinks={<HeaderCenterLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        // {...rest}
      />

      <Paper className={classes.paper}>
        <div
          style={{
            height: 75,
          }}
        ></div>
        {/* <GridContainer justify="center" style={{ paddingTop: "100px" }}>
          <GridItem xs={12} sm={10} md={8} align="left">
            <h3>
              <b>Inventory</b>
            </h3>
          </GridItem>
        </GridContainer> */}
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={10}
            md={8}
            style={{
              display: "inline-block",
              "text-align": "left",
              paddingBottom: "150px",
              // paddingTop: "10px",
            }}
          >
            <GridContainer justify="center">
            <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  padding: "5%"
                }}
              >
              <h3>
                <b>{inventory==0?"Inventory":inventory==2?"Inventory - Υπηρεσίες":"Inventory - Προϊόντα"}</b>
              </h3>
              </GridItem>
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={xartoki} width="100%" alt="PrintaForm"></img>
                <p className="title3">Χαρτοκιβώτια</p>
                <div>
                  Από την ποιο συνηθισμένη μορφή συσκευασίας RSC μέχρι τις πλέον
                  σύνθετες μορφές, με συνθέσεις χαρτονιού που ανταποκρίνονται
                  και στις πιο απαιτητικές εργασίες σε καφέ ή λευκό, και
                  δυνατότητα κατασκευής κυμάτωσης σε όλους του συνδυασμούς.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={xartina_koutia} width="100%" alt="PrintaForm"></img>
                <p className="title3">Χάρτινα Κουτιά</p>
                <div>
                  Έχοντας αποδείξει την ευαισθησία μας, δημιουργούμε συσκευασίες
                  από χαρτόνι κάθε είδους. Από απλά κουτιά μέχρι σύνθετες
                  απαιτητικές συνθέσεις και stand προβολής.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={etiketes} width="100%" alt="PrintaForm"></img>
                <p className="title3">Ετικέτες</p>
                <div>
                  Δημιουργούμε ετικέτες για βασική και απαιτητική συσκευασία,
                  απευθυνόμενοι στο σύνολο της εφοδιαστικής αλυσίδας. Η εκτύπωση
                  γίνεται με όλες τις γνωστές μεθόδους, αλλά και συνδυασμό τους
                  με: Offset, Letterpress, Φλεξογραφία και Ψηφιακή Εκτύπωση
                  (Digital Printing)
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={peritili} width="100%" alt="PrintaForm"></img>
                <p className="title3">Φιλμ Περιτύλιξης</p>
                <div>
                  Το γνωστό film για την περιτύλιξη παλετών, το οποίο παράγεται
                  από ατόφιο LLDPE (γραμμικό χαμηλής πυκνότητας πολυαιθυλένιο),
                  το οποίο είναι ατοξικό και ανακυκλώσιμο. Διατίθενται σε ρολά
                  χειρός και ρολά για μηχανές περιτύλιξης παλετών.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={thermosiriknosi} width="100%" alt="PrintaForm"></img>
                <p className="title3">Φιλμ Θερμοσυρρίκνωσης</p>
                <div>
                  Ένα εξαιρετικό προϊόν το οποίο «φοριέται» στον περιέκτη με
                  θερμοσυρρίκνωση δίνοντας εξαιρετικές δυνατότητες προβολής και
                  αξιοποίησης του χώρου της συσκευασίας.(νερού, χυμών Tetra-pack
                  κτλ.) Επίσης λειτουργεί προστατευτικά αποθαρρύνοντας το
                  άνοιγμα στο ράφι ή το ψυγείο.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%"
                }}
              >
                <img src={kollTainies} width="100%" alt="PrintaForm"></img>
                <p className="title3">Κολλητικές Ταινίες PPL</p>
                <div>
                  Αυτοκόλλητη ταινία με φορέα PP(πολυπροπυλενίου). Ιδανική για
                  εφαρμογή σε ακραίες συνθήκες θερμοκρασιών (π.χ ψυκτικοί
                  θάλαμοι και χώροι βαθιάς κατάψυξης). Διατίθεται σε διάφορα
                  πλάτη και μήκη ρολών.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={gonies} width="100%" alt="PrintaForm"></img>
                <p className="title3">Γωνιές παλετοποίησης</p>
                <div>
                  Παράγονται με χαρτί υψηλής αντοχής, ανθεκτικό στην υγρασία και
                  στην πίεση. Το εύρος των διαστάσεων των χάρτινων γωνιών είναι
                  από 15x15x1,5mm ως 100x100x7mm. Υπάρχει η δυνατότητα εκτύπωσης
                  λογότυπου στην εξωτερική επιφάνεια των παλετογωνιών.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={megaSakoi} width="100%" alt="PrintaForm"></img>
                <p className="title3">Μέγα Σάκοι Συσκευασίας</p>
                <div>
                  Οι μέγα σάκοι εγγυώνται ανθεκτικότατη συσκευασία για μεταφορά,
                  αποθήκευση και διαχείριση διαφόρων προϊόντων στερεών και υγρών
                  όπως χημικά, αγροτικά προϊόντα και τρόφιμα. Οι ανακυκλώσιμοι
                  μέγα σάκοι παρέχουν φίλτρο προστασίας UV με χωρητικότητα από
                  300 έως 2000 κιλά.
                </div>
              </GridItem>}
              {inventory!=2 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={typomenesTainies} width="100%" alt="PrintaForm"></img>
                <p className="title3">Τυπωμένες κολλητικές ταινίες</p>
                <div>
                  Οι τυπωμένες αυτοκόλλητες ταινίες προσωποποιούν, διαφημίζουν
                  και προσφέρουν ασφάλεια στα προϊόντα σας. Εκτυπώνουμε σε όλα
                  τα υλικά PP, PVC, PPL, και σε χαρτοταινίες με 1-3 χρώματα.
                </div>
              </GridItem>}
              {inventory!=1 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={grafiki} width="100%" alt="PrintaForm"></img>
                <p className="title3">Γραφική Ύλη</p>
                <div>
                  Φωτοτυπικό χαρτί, Επαγγελματικές κάρτες, Φάκελοι, και Ειδικά
                  επιστολόχαρτα.
                </div>
              </GridItem>}
              {inventory!=1 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={emporikaEntypa} width="100%" alt="PrintaForm"></img>
                <p className="title3">Εμπορικά Έντυπα</p>
                <div>
                  Πληθώρα έντυπων επιλογών βρίσκεται στην διάθεση σας
                  εξυπηρετώντας οποιαδήποτε μορφή επικοινωνίας και μάρκετινγκ.
                  Πολύπτυχα έντυπα με άπειρες επιλογές αναπτυγμάτων, Μπροσούρες,
                  Καρτολίνες, Συνταγολόγια, και News Letters.
                </div>
              </GridItem>}
              {inventory!=1 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={entypaAsfalias} width="100%" alt="PrintaForm"></img>
                <p className="title3">Έντυπα Ασφαλείας</p>
                <div>
                  Έχοντας επενδύσει στην γνώση για την αυθεντικότητα και την
                  ασφάλεια των συναλλαγών, σας προσφέρουμε εναλλακτικές λύσεις
                  με σκοπό την αποτροπή κάθε κακόβουλης ενέργειας.
                  Διαχειριζόμαστε σχεδόν το σύνολο των Έντυπων Επιταγών της
                  Κυπριακής Επικράτειας, απολαμβάνοντας την εμπιστοσύνη του
                  Τραπεζικού Συστήματος στο σύνολο του.
                </div>
              </GridItem>}
              {inventory!=1 && <GridItem
                xs={12}
                sm={4}
                md={4}
                style={{
                  padding: "5%",
                  paddingTop: "10%",
                }}
              >
                <img src={epistolikiEpikoinwnia} width="100%" alt="PrintaForm"></img>
                <p className="title3">Επιστολική επικοινωνία</p>
                <div>
                  Έχοντας σχεδόν 4 δεκαετίες εμπειρίας με την εμπιστοσύνη του
                  συνόλου των Κυπριακών Χρηματοπιστωτικών Ιδρυμάτων, της Αρχής
                  Τηλεπικοινωνιών Κύπρου (CYTA), πλήθους εμπορικών επιχειρήσεων
                  και της βιομηχανίας, σας προσφέρουμε εξαιρετικά μοντέλα
                  υπηρεσιών άρτια προσαρμοσμένα στις επικοινωνιακές σας ανάγκες.
                </div>
              </GridItem>}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      <Footer />
    </div>
  );
}
