import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/languageDecider'
import invReducer from './features/inventoryDecider'


export default configureStore({
  reducer: {
    language: counterReducer,
    inventory: invReducer,
  },
})