/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { toGreek, toEnglish } from '../../features/languageDecider'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { toStandard, toUpper,toLower } from '../../features/inventoryDecider'

const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {
  const inventory = useSelector((state) => state.inventory.value)
  const dispatch = useDispatch()

  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          Home
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => {
            dispatch(toStandard()) ;
            console.log("inventory",inventory)
          }}
          href="/inventory"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          Inventory
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/aboutus"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          About Us
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/contactus"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          Contact Us
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button
          href="/faq"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          {language ? "FAQ" : "CONTACT US"}
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/YouthTrams"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={() => {
            language ? dispatch(toEnglish()) : dispatch(toGreek());
          }}
        >
          {language ? "ΕΛ" : "EN"}
        </Button>
      </ListItem> */}
    </List>
  );
}
