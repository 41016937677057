/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { toGreek, toEnglish } from '../../features/languageDecider'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";

// @material-ui/icons
import { Apps, CloudDownload, Language } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {
  const language = useSelector((state) => state.language.value)
  const dispatch = useDispatch()

  const classes = useStyles();
  return (
    <List className={classes.list} style={{display: "flex", alignItems: "center"}}>
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          // onClick={() => {
          //   language ? dispatch(toEnglish()) : dispatch(toGreek());
          // }}
        ><Language/>
          CY/EN
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItem}>
          <Chip
          style={{backgroundColor: "#000000", color: "white"}} label="(+357) 22 461 144" />
      </ListItem>
    </List>
  );
}
