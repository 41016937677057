/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { useSelector } from 'react-redux'

// import Logo_tmp  from "assets/img/PrintFormBlack.PNG";
import { ReactComponent as Logo_tmp_white } from "../../assets/logo_white.svg";

import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";
import { CFormLabel } from "@coreui/react";

// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  // const language = useSelector((state) => state.language.value)
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer>
      <div className={classes.container}>
        <GridContainer
          justify="center"
          style={{
            color: "white",
            borderBottom: "1px solid",
            borderColor: "#444444",
          }}
        >
          <GridItem
            xs={12}
            sm={10}
            md={9}
            style={{
              textAlign: "center",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <GridContainer style={{ "align-items": "center" }}>
              <GridItem
                xs={12}
                sm={5}
                md={3}
                style={{ display: "flex", "justify-content": "center"}}
              >
                <GridItem
                  xs={7}
                  sm={10}
                  md={12}
                >
                  <Logo_tmp_white
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  />
                </GridItem>
              </GridItem>
              <GridItem
                xs={12}
                sm={7}
                md={9}
                align="center"
                style={{
                  textAlign: "right",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <Button
                  size="sm"
                  href="/"
                  color="transparent"
                  // target="_blank"
                  className={classes.navLink}
                >
                  Home
                </Button>
                <Button
                  size="sm"
                  href="/inventory"
                  color="transparent"
                  // target="_blank"
                  className={classes.navLink}
                >
                  Inventory
                </Button>
                <Button
                  size="sm"
                  href="/aboutus"
                  color="transparent"
                  // target="_blank"
                  className={classes.navLink}
                >
                  About Us
                </Button>
                <Button
                  size="sm"
                  href="/contactus"
                  color="transparent"
                  // target="_blank"
                  className={classes.navLink}
                >
                  Contact Us
                </Button>
                {/* <Button
                  size="sm"
                  href="/faq"
                  color="transparent"
                  // target="_blank"
                  className={classes.navLink}
                >
                  {language ? "FAQ" : "CONTACT US"}
                </Button> */}
                {/* <TextField
                  className="theFooterText"
                  style={{
                    backgroundColor: "white",
                    paddingRight: "0px",
                    "font-size": "small",
                    "border-radius": "25px",
                    margin: "0px",
                    paddingTop: "0px",
                  }}
                  type="email"
                  underlineShow={false}
                  placeholder="Email"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <Button
                        style={{
                          backgroundColor: "#ccda58",
                          color: "black",
                          "border-radius": "25px",
                          margin: "0px",
                        }}
                      >
                        {" "}
                        Subscribe
                      </Button>
                    ),
                  }}
                /> */}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer
          justify="center"
          style={{ color: "white", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <GridItem xs={12} sm={10} md={9} style={{ textAlign: "center" }}>
            <GridContainer style={{ "align-items": "center" }}>
              <GridItem
                xs={12}
                sm={6}
                md={9}
                style={{ "align-items": "center", textAlign: "left" }}

              >
               <CFormLabel                 onClick={()=>{window.open("https://theobsidianco.com/", '_blank').focus();
              }}>The Obsidian Co™</CFormLabel>
               {/* The Obsidian Co™ */}
              </GridItem>
              {/* <GridItem
                xs={12}
                sm={6}
                md={3}
                align="center"
                style={{ textAlign: "right", display: "flex", justifyContent: "space-between" }}
              >
                <IconButton
                  size="small"
                  style={{ border: "1px solid white", borderColor: "white" }}
                >
                  <FacebookIcon
                    style={{
                      margin: "5px",
                      color: "white",
                      fontSize: "smaller",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  style={{ border: "1px solid white", borderColor: "white" }}
                >
                  <InstagramIcon
                    style={{
                      margin: "5px",
                      color: "white",
                      fontSize: "smaller",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  style={{ border: "1px solid white", borderColor: "white" }}
                >
                  <TwitterIcon
                    style={{
                      margin: "5px",
                      color: "white",
                      fontSize: "smaller",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  style={{ border: "1px solid white", borderColor: "white" }}
                >
                  <LinkedInIcon
                    style={{
                      margin: "5px",
                      color: "white",
                      fontSize: "smaller",
                    }}
                  />
                </IconButton>
              </GridItem> */}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
