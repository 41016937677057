import React, { useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Home1 from "assets/img/Printaform_home_main.jpg";
import alambra from "assets/img/ALAMBRA cyprus.png";
import keo from "assets/img/KEO cyprus.png";
import charalambides from "assets/img/CharalambidesChristis cyprus.png";
import alfa from "assets/img/ALFA-PIZZA cyprus.png";
import falk from "assets/img/FALKSALT SAILOR cyprus.png";
import laiko from "assets/img/LAIKO-KAFEKOPTION cyprus.png";
import ikea from "assets/img/IKEA_cyprus.png";
import theThreeBakers from "assets/img/The-Three-bakers-cyprus.png";
import zara from "assets/img/Zara cyprus.png";
import prod from "assets/img/Proionta_syskeyasias.jpg";
// import sisk from "assets/img/Mixanographika_Entypa.jpg";
import sisk from "assets/img/NEWIMAGE.jpeg";

import entipi from "assets/img/entipi.JPG";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderCenterLinks from "components/Header/HeaderCenterLinks.js";


import charalambides_testimonial from "assets/img/testimonials/charalambides-christis.jpg";
import laikos_testimonial from "assets/img/testimonials/Laikos-group.jpg";
import maloupas_testimonial from "assets/img/testimonials/malouppas-papakostas-group.jpg";
//

// import { ReactComponent as Logo_tmp } from "../../assets/logo_tmp.svg";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Slider from "react-slick";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from 'react-redux'
import { toStandard, toUpper,toLower } from '../../features/inventoryDecider'


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 650,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxHeight: 440,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ],
  // centerPadding: "100px",
  // centerMode: true,
  // variableWidth: true
};

// const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const classes = useStyles();
  const inventory = useSelector((state) => state.inventory.value)
  const dispatch = useDispatch()
  // const [selected, setSelected] = React.useState([]);

  return (
    <div className={classes.root}>
      <Header
        rightLinks={<HeaderLinks />}
        centerLinks={<HeaderCenterLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        // {...rest}
      />

      <Paper className={classes.paper}>
        <div
          style={{
            height: 75,
          }}
        ></div>
        <div className="HomeBlack">
          <GridContainer justify="center" style={{ "align-items": "center" }}>
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  "text-align": "left",
                  paddingTop: "5px",
                }}
              >
                <p className="title_font">
                  Λύσεις Έντυπης Επικοινωνίας<br></br>και Προϊόντων Συσκευασίας
                </p>
                <p></p>
                <p className="sub_title_font">
                  <b>
                    Από το 1982 ηγούμαστε στην έντυπη επικοινωνία παρέχοντας
                    <br></br>
                    καινοτόμες υπηρεσίες προσποίησης, εμφακέλωσης εντύπων,
                    <br></br>
                    και συσκευασίας με την μέγιστη διασφάλιση των δεδομένων σας.
                  </b>
                </p>
                <br></br>

                <Button
                  href="/aboutus"
                  style={{
                    border: "2px solid white",
                    color: "white",
                    "border-radius": "25px",
                    "text-transform": "none",
                    marginBottom: "20px",
                  }}
                >
                  Read More
                </Button>
                <Button
                  href="/contactus"
                  style={{
                    marginLeft: "20px",
                    color: "black",
                    backgroundColor: "#fab124",
                    "border-radius": "25px",
                    "text-transform": "none",
                    marginBottom: "20px",
                  }}
                >
                  Contact us
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} style={{ padding: "0px" }}>
              <img width="100%" src={Home1} alt="PrintaForm"></img>
            </GridItem>
          </GridContainer>
        </div>
        <GridContainer style={{ "align-items": "center" }}>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <Slider {...settings}>
              <a className="PrintForm" href="https://petroubros.com.cy/">
                <img src={alambra} width={140} alt="PrintaForm"></img>
              </a>
              <a
                className="PrintForm"
                href="https://www.charalambideschristis.com.cy/"
              >
                <img src={charalambides} width={140} alt="PrintaForm"></img>
              </a>
              <a className="PrintForm" href="https://keogroup.com/">
                <img src={keo} width={140} alt="PrintaForm"></img>
              </a>
              <a className="PrintForm" href="https://www.alfapizza.com/">
                <img src={alfa} width={140} alt="PrintaForm"></img>
              </a>
              <a className="PrintForm" href="https://falksalt.com/">
                <img src={falk} width={140} alt="PrintaForm"></img>
              </a>
              <a
                className="PrintForm"
                href="http://www.laikocosmos.com/el/principal/peoples-coffee-manuf-ltd-el/"
              >
                <img src={laiko} width={140} alt="PrintaForm"></img>
              </a>
              <a className="PrintForm" href="https://www.ikea.com.cy/">
                <img src={ikea} width={140} alt="PrintaForm"></img>
              </a>
              <a
                className="PrintForm"
                href="http://www.thethreebakers.com/index.html"
              >
                <img src={theThreeBakers} width={140} alt="PrintaForm"></img>
              </a>
              <a className="PrintForm" href="https://www.zara.com/cy/">
                <img src={zara} width={140} alt="PrintaForm"></img>
              </a>
            </Slider>
          </GridItem>
        </GridContainer>
        <div style={{ backgroundColor: "#f4f4f4", paddingBottom: "50px" }}>
          <GridContainer justify="center" style={{ "align-items": "center" }}>
            <GridItem xs={10} sm={10} md={6} style={{ textAlign: "center" }}>
              <p className="title1">Τα προϊόντα και οι υπηρεσίες μας</p>
              <p className="text1">
                Με την αντίληψη στις ανάγκες σας και την επένδυση στη διαρκή
                βελτίωση του προσωπικού μας, πρωταρχικός<br></br>στόχος μας
                είναι οι επιτυχής λύσεις στον τομέα της Έντυπης επικοινωνίας και
                συσκευασίας.
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={10} sm={5} md={3} className="WhiteBackground">
              <img src={sisk} width="100%" alt="PrintaForm"></img>
              <p className="title2">
                Μηχανογραφικά Έντυπα και Έντυπη επικοινωνία
              </p>
              <div className="text2">
                Κατέχοντας ηγετική θέση στην αγορά των Μηχανογραφικών εντύπων,
                ακολουθούμε πιστά την εξέλιξη διαμορφώνοντας προστιθέμενη αξία
                στην έντυπη επικοινωνία.
              </div>
              <Button
                onClick={() => {
                  dispatch(toLower()) ;
                }}
                href="/inventory"
                style={{
                  marginLeft: "20px",
                  color: "white",
                  backgroundColor: "#f2bb4c",
                  "border-radius": "25px",
                  "text-transform": "none",
                  marginBottom: "20px",
                }}
              >
                Υπηρεσίες
              </Button>
            </GridItem>
            <GridItem
              xs={10}
              sm={5}
              md={3}
              className="WhiteBackground"
              style={{ padding: "0px" }}
            >
              <img src={prod} width="100%" alt="PrintaForm"></img>
              <p className="title2">Προϊόντα Συσκευασίας</p>
              <div className="text2">
                H συσκευασία δεν είναι ένα απλό περιτύλιγμα αλλά η υπόσχεση για
                ένα εξαιρετικό προϊόν.
              </div>
              <Button
                onClick={() => {
                  dispatch(toUpper()) ;
                }}
                href="/inventory"
                style={{
                  marginLeft: "20px",
                  marginBottom: "20px",
                  color: "white",
                  backgroundColor: "#f2bb4c",
                  "border-radius": "25px",
                  "text-transform": "none",
                }}
              >
                Όλα τα προϊόντα
              </Button>
            </GridItem>
          </GridContainer>
        </div>
        <div className="darkgreyBackground" style={{ padding: "30px" }}>
          <GridContainer justify="center" style={{ "align-items": "center" }}>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ textAlign: "center", border: "1px solid #5c5c5c" }}
            >
              <div className="WhiteBackground1">
                <GridContainer
                  justify="center"
                  style={{ "align-items": "center" }}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={9}
                    style={{ textAlign: "left" }}
                  >
                    <div className="testimonial">
                      Amazing product quality and excellent service team.
                    </div>
                    <p className="testimonial_name">
                      Μάριος Κωσταντίνου-CEO at Company Charalambides Christis Ltd
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <img
                      className="patriciaImage"
                      src={charalambides_testimonial}
                      width="100%"
                      alt="PrintaForm"
                    ></img>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ textAlign: "center", border: "1px solid #5c5c5c" }}
            >
              <div className="WhiteBackground1">
                <GridContainer
                  justify="center"
                  style={{ "align-items": "center" }}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={9}
                    style={{ textAlign: "left" }}
                  >
                    <div className="testimonial">
                      Printaform’s team has impressed us with their attention to
                      detail and expertise to select what was better for our
                      business! We plan to continue to work with them for the
                      long term.
                    </div>
                    <p className="testimonial_name">
                      {
                        "Pavlos Benakis-Group COO at Mallouppas & Papacostas Public Co. Ltd"
                      }
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <img
                      className="patriciaImage"
                      src={maloupas_testimonial}
                      width="100%"
                      alt="PrintaForm"
                    ></img>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ textAlign: "center", border: "1px solid #5c5c5c" }}
            >
              <div className="WhiteBackground1">
                <GridContainer
                  justify="center"
                  style={{ "align-items": "center" }}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={9}
                    style={{ textAlign: "left" }}
                  >
                    <div className="testimonial">
                      We have always looked for external partners that matched
                      our core values and with the people at Printaform we found
                      our perfect match! Keep up the good work!!
                    </div>
                    <p className="testimonial_name">Μιχάλης Παρίδης-General Manager at Laiko Group</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <img
                      className="patriciaImage"
                      src={laikos_testimonial}
                      width="100%"
                      alt="PrintaForm"
                    ></img>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Paper>
      <Footer />
    </div>
  );
}
