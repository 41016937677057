import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import store from './store'
import { Provider } from 'react-redux'

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import AboutUs from "views/AboutUs/Components.js";
import Contactus from "views/Contactus/Components.js";
// import FAQ from "views/FAQ/Components.js";
import Inventory from "views/Inventory/Components.js";
// import ResearchPage from "views/ResearchPage/ResearchPage.js";
// import MethodologyPage from "views/MethodologyPage/Methodology.js";
// import ReportsPage from "views/ReportsPage/Methodology.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/contactus" component={Contactus} />
        {/* <Route path="/faq" component={FAQ} /> */}
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/inventory" component={Inventory} />
        {/* <Route path="/reportspublications" component={ReportsPage} />
        <Route path="/research-page" component={ResearchPage} />
        <Route path="/methodology" component={MethodologyPage} /> */}
        <Route path="/" component={Components} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
