import React, { useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AboutUsPhoto from "assets/img/AboutUsPhotoTop.jpg";
import AboutUsPhoto2 from "assets/img/AboutUsPhotoBottom.jpg";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderCenterLinks from "components/Header/HeaderCenterLinks.js";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 650,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxHeight: 440,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // centerPadding: "100px",
  // centerMode: true,
};

// const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const classes = useStyles();

  // const [selected, setSelected] = React.useState([]);

  return (
    <div className={classes.root}>
      <Header
        // <Logo_tmp
        //   onClick={() => {
        //     window.location.href = "/";
        //   }}
        // />

        rightLinks={<HeaderLinks />}
        centerLinks={<HeaderCenterLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        // {...rest}
      />

      <Paper className={classes.paper}>
        <div
          style={{
            height: 75,
          }}
        ></div>
        <img src={AboutUsPhoto} width="100%" alt="PrintaForm"></img>
        <GridContainer justify="center" style={{ "align-items": "center" }}>
          <GridItem
            xs={10}
            sm={10}
            md={6}
            style={{ display: "inline-block", "text-align": "left" }}
          >
            <p className="AboutUsTitle">Ποιοι είμαστε</p>

            <p>
              Από το 1982 ηγούμαστε στην Έντυπη Επικοινωνία παρέχοντας
              καινοτόμες λύσεις στη διακίνηση και διασφάλιση των δεδομένων σας.
            </p>
            <br></br>
            <p>
              Αφουγκραζόμενοι τις ανάγκες των πελατών μας, το 2015 αποφασίσαμε
              να ενσωματώσουμε στις δραστηριότητες μας την Διεύθυνση Προϊόντων
              Συσκευασίας, παρέχοντας καινοτόμες λύσεις υψηλής αισθητικής και
              λειτουργικότητας τόσο στην Κύπρο όσο και στο εξωτερικό.
            </p>
            <br></br>
            <p className="AboutUsTitle">Ξεχωρίζουμε</p>

            <p>Γιατί αντιλαμβανόμαστε τις ανάγκες.</p>
            <p>
              {" "}
              Γιατί σχεδιάζουμε και παρέχουμε λύσεις Έντυπης Επικοινωνίας και
              Συσκευασίας έχοντας επενδύσει σε εξοπλισμό νέας γενιάς και
              εξαιρετικές συνεργασίες.
            </p>
            <p>Γιατί επενδύουμε στη διαρκή βελτίωση του προσωπικού μας.</p>
            <p>
              Γιατί όλοι οι συνεργαζόμενοι οίκοι και οι αντιπροσωπίες μας,
              πληρούν όλους τους κανόνες παραγωγής, υγιεινής και ασφάλειας της
              βιομηχανίας κατέχοντας όλες τις απαραίτητες πιστοποιήσεις.
            </p>
            <p>
              Γιατί μας διακρίνει η αυστηρότητα καθ’ όλη τη διάρκεια της
              παραγωγικής διαδικασίας, τις συνθήκες αποθήκευσης και της ασφαλούς
              διακίνησης.
            </p>
            <p>
              Γιατί σας συμβουλεύουμε. Είμαστε η εταιρική σας προέκταση μέσω της
              εμπιστοσύνης σας.
            </p>
            <br></br>

            <p className="AboutUsTitle">Το Όραμα μας</p>

            <p>
              Να δημιουργήσουμε προστιθέμενη αξία στους πελάτες μας προσφέροντας
              εξαιρετικές λύσεις. Με τη διάθεση μας να νοιαζόμαστε περισσότερο
              από ότι οι υπόλοιποι θεωρούν περιττό, εμείς δεσμευόμαστε να
              δημιουργούμε κουλτούρα που αναπαράγει φίλους Πελάτες και
              Προμηθευτές.
            </p>
            <br></br>
            <br></br>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <img src={AboutUsPhoto2} width="100%" alt="PrintaForm"></img>
          </GridItem>
        </GridContainer>
        <GridContainer
          justify="center"
          style={{ "align-items": "center", paddingBottom: "100px" }}
        >
          <GridItem xs={12} sm={10} md={6} style={{ "text-align": "center" }}>
            <p className="AboutUsTitle1">Η ομάδα μας</p>
            <p>
              "Ανήκουμε στους κορυφαίους επαγγελματίες της Έντυπης επικοινωνίας
              και της Συσκευασίας. Τα συστατικά της επιτυχίας μας είναι οι
              ταλαντούχοι άνθρωποι που απαρτίζουν την ομάδα, οι οποίοι
              διαμορφώνουν τις αξίες μας με πνεύμα ομαδικής εργασίας και
              ειλικρινείς σχέσεις με τους πελάτες μας αλλά και μεταξύ των μελών
              της ομάδας."
            </p>

            <p className="AboutUsTitle1">Διοίκηση</p>
            <p>
              Emilios Koullouros (General Manager)
              <br></br>
              emilioskoullouros@printaform.com.cy
            </p>

            <p className="AboutUsTitle1">Υπεύθυνος Παραγωγής</p>
            <p>
              Κύπρος Χαλουβάς (Production Manager)
              <br></br>
              kypros.chalouvas@printaform.com.cy
            </p>

            <p className="AboutUsTitle1">Τμήμα Πωλήσεων</p>
            <p>
              Πόλυς Πολυκάρπου (Sales Executive)<br></br>Ανδρέας Χατζηχρίστου
              (Sales Executive)<br></br>Έλενα Κεραμιδά (Sales Assistant)
              <br></br>sales@printaform.com.cy
            </p>

            <p className="AboutUsTitle1">Οικονομικό Τμήμα</p>
            <p>
              Νεόφυτος Λάμπρου (Accounts Manager)<br></br>
              neophytos.lambrou@printaform.com.cy
            </p>
            <p>
              Στέλλα Μαμμούς (Economics Department)<br></br>
              accounting@printaform.com.cy
            </p>

            <p className="AboutUsTitle1">Graphics</p>
            <p>
              Άννα Φίτσιου (Graphic Designer)<br></br>graphics@printaform.com.cy
            </p>

            <p className="AboutUsTitle1">IT/Digital Printing</p>
            <p>
              Ανδρέας Ιωάννου (IT)<br></br>andreas.ioannou@printaform.com.cy
            </p>
          </GridItem>

          {/* <GridItem xs={10} sm={10} md={6} style={{ display: "inline-block", "text-align": "left" }}>
            <p className="AboutUsTitle1">We supply the steels for onshore platforms </p>
                <p>Beyond Packaging</p>
                <p>Ψάχνοντας τους κρυμμένους πρωταθλητές</p>
                <br></br>
                <p>Συσκευασία- Μια ιστορία επιτυχίας</p>
                <br></br>
                <p>Η στσκεασία πρδιορίζεται μέσα από μια ιστορική διαδρομή επιτυχίας . Ειναι αναπόσπαστο μέρος της καθημερινότητας και αυτό δεν ειναι τυχαίο. Δεν έχει σημασία αν μιλάμε για τρόφιμα  φάρμακα η ηλεκτρικα είδη.Χωρις συσκαευασόα τα περισσότερα από αυτά θα καταστράφουν η στην καλύτερη περίπτωση θα χαλάσουν πόλυ πριν φτάσουν σε εμάς</p>
                <p><br></br></p>
                <p>Αυτο δεν σημαίνει οτι δεν μπορούμε να ζήσουμε καλύτερα απλα χρησιμοποιώντας περισσότερα συσκευάσιες και επίσης δεν σημάιονει ότι κάθε ειδός συσκευασίας είναι απαιραιτήτως εξαιρετικά κατασκευασμλενο και χρήσιμο . Αλλά δείχνει ότι δεν μπρόυμε να ζήσουμε χωρίς την συσκευασία . Και δείχνει επίσης πόσο ουσιαστκά συναρπαστικό είναι το θλεμα συσκεασία  </p>
                <br></br>
                <p>Στο παραδοσιακό οκογενεικό επιχειρηματικό περιβάλλον της Κύπρου υπάρχουν πολλοί κρυμμένοι πρωταθλητές που καθημερινά αναρτώνται στον πινακα επιτυχιών μεσω της συσλευασίας τους </p>
                <br></br>
                <p>Εμεις γράφουμε αυτή την ιστορια μαζι σας </p>
            </GridItem> */}
        </GridContainer>
      </Paper>
      <Footer />
    </div>
  );
}
